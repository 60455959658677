import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import anime from 'animejs'
import { App, plugin } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress/src'
import '../js/filters'
import Lang from './Lang'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import '../assets/css/tailwind/app.css'
import '../assets/css/sass/app.scss'
import md5 from 'md5'
window.md5 = md5

Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.use(Vuex);

axios.interceptors.response.use((response) => response, error => {
    if (error.response.status === 419) {
        window.location = '/login?code=419';
    }

    return Promise.reject(error);
});


InertiaProgress.init()

Vue.prototype.$http = axios;
Vue.prototype.$anime = anime;


Vue.mixin({
    methods: {
        translate(file, data) {
            return Lang.get(file, data)
        }
    }
});

import auth from './store/modules/auth';

const store = new Vuex.Store({
    strict: true,
    modules: {
        auth,
    }
})

import Panel from './Pages/Auth/Panel';
import RegisterVerification from './Pages/Auth/Register/RegisterVerification';
import Register from './Pages/Auth/Register/Register';
import LoginHeader from "./Pages/Auth/Login/LoginHeader";
import LoginFooter from "./Pages/Auth/Login/LoginFooter"

Vue.component('Panel', Panel);

const el = document.getElementById('app')

if(el) {
    new Vue({
        store,
        metaInfo: {
            titleTemplate: title => (title ? `${title} - Nimbus` : 'Nimbus'),
        },

        render: h =>
            h(App, {
                props: {
                    initialPage: JSON.parse(el.dataset.page),
                    resolveComponent: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')).then(d => d.default),
                },
            })
    }).$mount(el)
}
else {
    new Vue({
        el: '#guest-app',
        store,
        components: {
            RegisterVerification,
            Register,
            LoginHeader,
            LoginFooter
        }
    });
}
