const state = {
    email: '',
    password: '',
    secret: '',
    rememberEmail: true,
    rememberSecret: true,
    active: false,
};

const getters = {
    email (state) {
        return state.email;
    },
    password (state) {
        return state.password;
    },
    rememberEmail (state) {
        return state.rememberEmail;
    },
    rememberSecret (state) {
        return state.rememberSecret;
    },
    secret (state) {
        return state.secret;
    },
    active (state) {
        return state.active;
    }
};

const actions = {
    attemptPreFlight ({commit, state}) {
        return this._vm.$http.post('login/preflight', {
            email: state.email,
        });
    },
    attemptLogin ({state}) {
        return this._vm.$http.post('login', {
            email: state.email,
            password: state.password
        });
    },
    verifyTwoFactorAuthentication ({state}) {
        return this._vm.$http.post('login/verify-two-factor-authentication', {
            secret: state.secret,
            remember: state.rememberSecret
        })
    },
    resendInvitations ({state}) {
        return this._vm.$http.post('account/resend-invitations', {
            email: state.email
        });
    },
    changeEmail ({commit, state}) {
        return new Promise((resolve, reject) => {
            return this._vm.$http.post('login/forget').then(response => {
                commit('setEmail', '');
                commit('setPassword', '');

                resolve(response);
            }).catch(errors => reject(errors));
        });
    }
};

const mutations = {
    setEmail (state, payload) {
        state.email = payload;
    },
    setPassword (state, payload) {
        state.password = payload;
    },
    setRemember (state, payload) {
        state.remember = payload;
    },
    setRememberEmail (state, payload) {
        state.rememberEmail = payload;
        if (payload) {
            localStorage.setItem('remember-email', state.email);
        } else {
            localStorage.removeItem('remember-email');
        }
    },
    setSecret (state, payload) {
        state.secret = payload;
    },
    setRememberSecret (state) {
        state.rememberSecret = !state.rememberSecret;
    },
    setActive (state, payload) {
        state.active = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};